<template>
  <div class="page-container">
    <Breadcrumbs />
    <Transition name="fade">
      <div
        class="policy"
        v-if="!pageLoading"
      >
        <TextContent :content="pageContent.Content"></TextContent>
      </div>
    </Transition>
  </div>
</template>

<script>
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import TextContent from '../../components/TextContent.vue';
import { animationMixin } from '@/components/mixins/animation';

// API
import api from '@/tools/api';

const md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true,
});

export default {
  name: 'PrivacyPolicy',
  mixins: [animationMixin],
  components: {
    Breadcrumbs,
    TextContent,
  },
  data() {
    return {
      currentSection: undefined,
      pageLoading: true,
      pageContent: {},
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('page-policy-content', true);
      console.log(results);
      this.pageLoading = !results;
      this.pageContent = results;
    },
    markdownToHTML(content) {
      return md.render(content);
    },
  },
  watch: {
    currentLang() {
      this.getPageContent();
    },
  },
  created() {
    this.getPageContent();
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.policy {
  position: relative;

  @include wRule(padding-top, (xl: 270, lg: 205, md: 162, sm: 160));
  @include wRule(padding-bottom, (xl: 100, lg: 45, md: 70, sm: 150));
}
</style>
